import api from "apis";
import { PaginatedResponse, PaginationParams } from "apis/types/general";
import { AllStaffShiftsProps } from "apis/types/shift";
import getSerializedQueryArgs from "utils/getSerializedQueryArgs";

export const shiftApi = api.injectEndpoints({
  endpoints: (build) => ({
    getMyShifts: build.query<
      PaginatedResponse<AllStaffShiftsProps>,
      PaginationParams
    >({
      query: (params) => ({
        url: "/shifts/my_shifts/",
        params: {
          ordering: "-id",
          ...Object.fromEntries(
            Object.entries(params).filter(([_, v]) => v !== "")
          ),
        },
      }),
      providesTags: ["Shift"],
      serializeQueryArgs: getSerializedQueryArgs,
    }),

    getMyEmployeeShiftsConflicts: build.query<
      PaginatedResponse<AllStaffShiftsProps>,
      PaginationParams
    >({
      query: (params) => ({
        url: "/shifts/my_staff_shifts_and_conflicts/",
        params: {
          // ordering: "-id",
          ...Object.fromEntries(
            Object.entries(params).filter(([_, v]) => v !== "")
          ),
        },
      }),
      providesTags: (res, err, arg) => [
        "EmployeeShiftS",
        { type: "Shift", id: arg?.shift_type },
      ],
      serializeQueryArgs: getSerializedQueryArgs,
    }),

    getMyEmployeeShifts: build.query<
      PaginatedResponse<AllStaffShiftsProps>,
      PaginationParams
    >({
      query: (params) => ({
        url: "/shifts/my_staff_shifts/",
        params: {
            ...Object.fromEntries(
            Object.entries(params).filter(([_, v]) => v !== "")
          ),
        },
      }),
      providesTags: (res, err, arg) => [
        "EmployeeShiftS",
        { type: "Shift", id: arg?.shift_type },
      ],
      serializeQueryArgs: getSerializedQueryArgs,
    }),

    getMyCollectedEmployeeShifts: build.query<
      PaginatedResponse<AllStaffShiftsProps>,
      PaginationParams
    >({
      query: (params) => ({
        url: "/collected_shifts/",
        params: {
          ordering: "-id",
          ...Object.fromEntries(
            Object.entries(params).filter(([_, v]) => v !== "")
          ),
        },
      }),
      providesTags: (res, err, arg) => [
        "EmployeeShiftS",
        { type: "Shift", id: arg?.shift_type },
      ],
      serializeQueryArgs: getSerializedQueryArgs,
    }),

    getMyShiftDetails: build.query({
      query: (shidtId) => ({
        url: `/shifts/my_shifts/${shidtId}/`,
      }),
      providesTags: (res, err, arg) => ["Shift", { type: "Shift", id: arg }],
    }),

    getEmployeeShiftDetails: build.query({
      query: (shidtId) => ({
        url: `/shifts/my_staff_shifts/${shidtId}/`,
      }),
      providesTags: (res, err, arg) => [
        "EmployeeShift",
        { type: "Shift", id: arg },
      ],
    }),
    editEmployeeShift: build.mutation({
      query: (body) => ({
        url: `/shifts/my_staff_shifts/${body.id}/`,
        method: "PATCH",
        body: { ...body },
      }),
      invalidatesTags: ["EmployeeShiftS"],
    }),

    assignFixedShift: build.mutation({
      query: (body) => ({
        url: "/shifts/fixed_shift_templates/assign_fixed_shift/",
        method: "POST",
        body: { ...body },
      }),
      invalidatesTags: ["EmployeeShiftS"],
    }),

    deleteAssignedShift: build.mutation({
      query: (id) => ({
        url: `/shifts/my_staff_shifts/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["EmployeeShiftS"],
    }),

    assignBasedShift: build.mutation({
      query: (body) => ({
        url: "/shifts/based_shifts/assign_based_shift/",
        method: "POST",
        body: { ...body },
      }),
      invalidatesTags: ["EmployeeShiftS"],
    }),

    assignFlexibleShift: build.mutation({
      query: (body) => ({
        url: "/shifts/flexible_shifts/assign_flexible_shift/",
        method: "POST",
        body: { ...body },
      }),
      invalidatesTags: ["EmployeeShiftS"],
    }),
    getShiftsStatistics: build.query({
      query: (params) => ({
        url: "/my_shifts/statistics/",
        params,
      }),
      providesTags: ["EmployeeShiftS"],
    }),
    getStaffShiftsStatistics: build.query({
      query: (params) => ({
        url: "/my_staff_shifts/statistics/",
        params,
      }),
      providesTags: ["EmployeeShiftS"],
    }),

    getConflictDetials: build.query({
      query: (params) => ({
        url: `/conflict/${params.id}/`,
        params,
      }),
      providesTags: ["ShiftTypes", "EmployeeShiftS"],
    }),

    updateConflict: build.mutation({
      query: (body) => ({
        url: `/conflict/${body.id}/`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["ShiftTypes", "EmployeeShiftS", "Checkins"],
    }),

    getBulkUploadLogs: build.query({
      query: (params) => ({
        url: `/uploads-logs/`,
        params,
        // method: "PUT",
        // body,
      }),
    }),
  }),
});

export const {
  useGetMyShiftsQuery,
  useLazyGetMyShiftsQuery,
  useGetMyEmployeeShiftsQuery,
  useGetMyCollectedEmployeeShiftsQuery,
  useLazyGetMyEmployeeShiftsQuery,
  useGetMyShiftDetailsQuery,
  useGetEmployeeShiftDetailsQuery,
  useAssignFixedShiftMutation,
  useAssignBasedShiftMutation,
  useAssignFlexibleShiftMutation,
  useDeleteAssignedShiftMutation,
  useGetMyEmployeeShiftsConflictsQuery,
  useGetShiftsStatisticsQuery,
  useGetStaffShiftsStatisticsQuery,
  useGetConflictDetialsQuery,
  useLazyGetConflictDetialsQuery,
  useUpdateConflictMutation,

  useEditEmployeeShiftMutation,
  useGetBulkUploadLogsQuery,
} = shiftApi;
