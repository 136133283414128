import ModalWrapper from "components/modals/ModalWrapper";
import { Form } from "antd";
import TextInput from "components/inputs/TextInput";
import showSuccessMsg from "utils/showSuccessMsg";
import { useGetZonesQuery } from "apis/services/zone";
import SelectionInput from "components/inputs/SelectionInput";
import {
  useAddLocationMutation,
  useEditLocationMutation,
  useGetProjectsQuery,
} from "apis/services/locations";
import { AddNewLocationModalProps } from "./types";

export default function AddNewLocationModal({
  isVisible,
  setIsVisible,
  isUpdate,
  initialValues,
}: AddNewLocationModalProps) {
  const [form] = Form.useForm();

  const { data: zones } = useGetZonesQuery({ page_size: 100 });
  const { data: projects } = useGetProjectsQuery({
    page_size: 100,
  });

  const zoneOptions = zones?.results?.map((item: any) => ({
    label: item.name,
    value: item.id,
  }));
  const projectIDsOptions = projects?.results?.map((item: any) => ({
    label: `${item.name} (${item.project_id})`,
    value: item.id,
    projectId: item.project_id,
  }));

  const [addLocation, { isLoading: isAddLocationLoading }] =
    useAddLocationMutation();
  const [editLocation, { isLoading: isEditLocationLoading }] =
    useEditLocationMutation();

  const getProjectId = (value: string) =>
    projectIDsOptions?.find((item: any) => +value === +item.projectId)?.value;

  const addNewZone = (values: any) => {
    addLocation(values)
      .unwrap()
      .then(() => {
        setIsVisible(false);
        form.resetFields();
        showSuccessMsg({ msg: "Added new location successfully!" });
      });
  };
  const editLocationFN = (values: any) => {
    const request_values = {
      ...values,
      id: initialValues?.id,
    };
    editLocation(request_values)
      .unwrap()
      .then(() => {
        setIsVisible(false);
        form.resetFields();
        showSuccessMsg({ msg: "Edited location successfully!" });
      });
  };

  const submitForm = () => {
    form.submit();
  };

  return (
    <ModalWrapper
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      size="large"
      headerTitle={isUpdate ? "Edit Location" : "Add New Location"}
      onConfirm={submitForm}
      isLoading={isAddLocationLoading || isEditLocationLoading}
    >
      <Form
        form={form}
        onFinish={isUpdate ? editLocationFN : addNewZone}
        initialValues={
          isUpdate
            ? {
                ...initialValues,
                project_id: getProjectId(initialValues.project_id) || undefined,
                zone: initialValues?.zone?.id,
              }
            : {}
        }
        layout="vertical"
        scrollToFirstError
        className="w-100"
      >
        <Form.Item
          name="name"
          label="Location Name"
          rules={[{ required: true }]}
        >
          <TextInput />
        </Form.Item>

        <Form.Item name="zone" label="Zone" rules={[{ required: true }]}>
          <SelectionInput options={zoneOptions} />
        </Form.Item>

        <Form.Item name="address" label="Address" rules={[{ required: true }]}>
          <TextInput />
        </Form.Item>

        <Form.Item
          name="latitude"
          label="Latitude"
          rules={[{ required: true }, { type: "number" }]}
        >
          <TextInput isNumberInput />
        </Form.Item>

        <Form.Item
          name="longitude"
          label="Longitude"
          rules={[{ required: true }, { type: "number" }]}
        >
          <TextInput isNumberInput />
        </Form.Item>
        <Form.Item
          name="project_id"
          label="Project ID"
          rules={[{ required: true }, { type: "number" }]}
        >
          <SelectionInput options={projectIDsOptions} />
        </Form.Item>

        <Form.Item
          name="radius"
          label="Radius"
          rules={[{ required: true }, { type: "number" }]}
        >
          <TextInput isNumberInput />
        </Form.Item>

        <Form.Item
          name="description"
          label="Description"
          rules={[{ required: true }]}
        >
          <TextInput type="textarea" />
        </Form.Item>
      </Form>
    </ModalWrapper>
  );
}
