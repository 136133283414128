import Button from "components/general/Button";
import { useState } from "react";
import AddNewLocationModal from "components/modals/AddNewLocationModal";
import {
  useDeleteLocationMutation,
  useGetLocationsQuery,
} from "apis/services/locations";
import Table from "components/general/Table";
import { useSearchParams } from "react-router-dom";
import SecondaryPageHeader from "components/general/SecondaryPageHeader";
import Text from "components/general/Text";
import MenuTableDataWrapper from "components/general/MenuTableDataWrapper";
import { MenuDataItem } from "components/general/MenuTableDataWrapper/types";
import showSuccessMsg from "utils/showSuccessMsg";
import { Tooltip } from "antd";
import IMPORTANT_VARS from "constants/ImportantVars";
import styles from "./styles.module.scss";

export default function Locations() {
  const [searchParams] = useSearchParams();

  const [isAddNewLocationModalVisible, setisAddNewLocationModalVisible] =
    useState(false);
  const [isEditLocation, setisEditLocation] = useState(false);
  const [selectedLocation, setselectedLocation] = useState({});

  const { data: locations, isFetching: isLocationsLoading } =
    useGetLocationsQuery({
      ordering: "-id",
      page: searchParams.get("page") ?? 1,
      page_size: IMPORTANT_VARS.TabelDataPageSize,
    });

  const [deleteLocation, { isLoading: isDeletingLocationLoading }] =
    useDeleteLocationMutation();

  const onGetDeleteLocationFunction = (locationId: number) => () => {
    deleteLocation(locationId)
      .unwrap()
      .then(() => {
        showSuccessMsg({ msg: "This location has been deleted successfully!" });
      });
  };
  const onEditFunction = (location: any) => () => {
    setisEditLocation(true);
    setselectedLocation(location);
  };

  const getLocationMenuData = (location: any): MenuDataItem[] => [
    {
      key: "edit",
      label: "Edit",
      isDanger: false,
      isLoading: false,
      actionFn: onEditFunction(location),
    },
    {
      key: "delete",
      label: "Delete",
      isDanger: true,
      isLoading: isDeletingLocationLoading,
      actionFn: onGetDeleteLocationFunction(location?.id),
    },
  ];

  const tabelData =
    locations?.results?.map((item: any) => ({
      id: item.id,
      rowData: [
        {
          dataItem: item.name,
          type: "text",
        },
        {
          dataItem: item.address,
          type: "text",
        },
        {
          dataItem: item.number_of_members,
          type: "text",
        },
        {
          dataItem: item.project_id,
          type: "text",
        },
        {
          dataItem: item.radius,
          type: "text",
        },
        {
          dataItem: (
            <MenuTableDataWrapper menuData={getLocationMenuData(item)}>
              <Tooltip title={item.description} id={item.id}>
                <div className="pointer">
                  <Text className={styles.description}>{item.description}</Text>
                </div>
              </Tooltip>
            </MenuTableDataWrapper>
          ),
          type: "element",
        },
      ],
    })) || [];

  const onShowAddModal = () => {
    setisAddNewLocationModalVisible(true);
  };
  const onCloseModal = () => {
    setisAddNewLocationModalVisible(false);
    setisEditLocation(false);
  };

  const tabelHeaders = [
    "Location Name",
    "Address",
    "Members num.",
    "project ID",
    "Radius",
    "Description",
  ];

  const shouldShowModal = isAddNewLocationModalVisible || isEditLocation;

  return (
    <div>
      <SecondaryPageHeader
        title="Locations"
        RightComponent={
          <Button btnClassName={styles.headerBtn} onClick={onShowAddModal}>
            Add New Location
          </Button>
        }
      />

      <div>
        <Table
          containerStyle={styles.locationContainer}
          headers={tabelHeaders}
          isDataCentered
          isHeaderCentered
          isLoading={isLocationsLoading}
          data={tabelData}
          isCheckBoxVisible={false}
          count={locations?.count}
          pageSize={IMPORTANT_VARS.TabelDataPageSize}
        />
      </div>
      {shouldShowModal && (
        <AddNewLocationModal
          isVisible={shouldShowModal}
          setIsVisible={onCloseModal}
          isUpdate={isEditLocation}
          initialValues={selectedLocation}
        />
      )}
    </div>
  );
}
