/* eslint-disable */
import { useSearchParams } from "react-router-dom";
import Table from "components/general/Table";
import UserInfo from "components/cards/UserInfo";
import { AllStaffShiftsProps } from "apis/types/shift";
import { TableItemProps } from "components/general/Table/types";
import { useGetBulkUploadLogsQuery } from "apis/services/shift";
import formatTime from "utils/formatDateTime";
import IMPORTANT_VARS from "constants/ImportantVars";
import useGetUserInfo from "hooks/useGetUserInfo";
import TabHeader from "./TabHeader";
import styles from "./styles.module.scss";
import formateDate from "utils/formatDate";

export default function BulkUploadLog({
  statisticts,
  isLoading,
}: {
  statisticts?: any;
  isLoading?: boolean;
}) {
  const [searchParams] = useSearchParams();

  const { role } = useGetUserInfo();

  const { data, isFetching: isBulkUploadLoading } = useGetBulkUploadLogsQuery({
    search: searchParams.get("search") ?? "",
    page: searchParams.get("page") ?? 1,
    page_size: IMPORTANT_VARS.TabelDataPageSize,
    
  });

  const changeShiftData = (item: any): TableItemProps => ({
    id: String(item.id),
    rowData: [
      // {
      //   type: "text",
      //   dataItem: <UserInfo data={item?.staff} isTableCol />,
      // },
      { type: "text", dataItem: item?.file_name ?? "-" },
      { type: "text", dataItem: item?.upload_status ?? "-" },

      {
        type: "text",
        dataItem: formateDate(item.created_at) ?? "-",
      },
      {
        type: "text",
        dataItem: item.error_message || "-",
      },
      {
        type: "element",
        dataItem: <></>,
      },
    ],
  });

  return (
    <div className={styles.container}>
      <TabHeader title={"Bulk Upload Log"} showBtns={false} />

      <div className="my-4">
        <Table
          isCheckBoxVisible
          showSearchFilter={false}
          headers={[
            // "Members ID",
            "File Name",
            "Upload Status",
            "Date",
            "Error Message",
            "",
          ]}
          data={
            data?.results.map((item: AllStaffShiftsProps) =>
              changeShiftData(item)
            ) ?? []
          }
          isLoading={isBulkUploadLoading || isLoading}
          count={data?.count}
          pageSize={IMPORTANT_VARS.TabelDataPageSize}
        />
      </div>
    </div>
  );
}
