/* eslint-disable */
import UserForm from "components/pages/settings/UserForm";
import { useParams, useSearchParams } from "react-router-dom";
import {
  useGetBalanceDetailsForUserQuery,
  useGetSpecificStaffFromAllStaffQuery,
} from "apis/services/staff";
import LoadingIndicator from "components/general/LoadingIndicator";
import Paper from "components/general/PaperComponent";
import { ConfigProvider, Tabs, Tooltip } from "antd";
import { useState } from "react";
import Table from "components/general/Table";
import UserInfo from "components/cards/UserInfo";
import MenuTableDataWrapper from "components/general/MenuTableDataWrapper";
import dayjs from "dayjs";
import COLORS from "constants/Colors";
import styles from "./styles.module.scss";
import Text from "components/general/Text";
import ModalWrapper from "components/modals/ModalWrapper";
import EditUserBalance from "components/modals/EditUserBalance";

export default function EditUser() {
  const params = useParams();
  const userId = params?.id;
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedTab, setSelectedTab] = useState("info");
  const [isEditBalance, setisEditBalance] = useState(false);
  const [selectedBalance, setselectedBalance] = useState({});
  const { data, isFetching: isGettingUserLoading } =
    useGetSpecificStaffFromAllStaffQuery(Number(userId));
  const { data: balaceData, isFetching: balanceLoader } =
    useGetBalanceDetailsForUserQuery({
      staff: userId,
      page: searchParams.get("page") ?? 1,
    });
  console.log(userId);

  const onEditFunction = (balance: any) => () => {
    setisEditBalance(true);
    setselectedBalance(balance);
  };
  const getLocationMenuData = (balance: any): any[] => [
    {
      key: "edit",
      label: "Edit",
      isDanger: false,
      isLoading: false,
      actionFn: onEditFunction(balance),
    },
  ];
  const headers = ["Leave Type", "Date", "Alloted", "Used", "Balance", ""];
  const items = [
    {
      key: "info",
      label: "Profile information",
    },
    {
      key: "balance",
      label: "Customize Balance",
    },
  ];
  const changeBalanceData = (item: any): any => ({
    id: String(item.id),
    rowData: [
      {
        type: "element",
        dataItem: `${item?.leave_policy_name}`,
      },
      {
        type: "text",
        dataItem: dayjs(item?.updated_at).format("DD-MM-YYYY") ?? "-",
      },
      {
        type: "text",
        dataItem: item.total || "-",
      },
      {
        type: "text",
        dataItem: item.used || "-",
      },
      { type: "text", dataItem: item.balance || "-" },
      {
        type: "element",
        dataItem: <MenuTableDataWrapper menuData={getLocationMenuData(item)} />,
      },
    ],
  });
  const onChange = (key: string) => {
    searchParams.set("page", "1");
    setSearchParams(searchParams);
    setSelectedTab(key);
  };
  return (
    <div className={styles.container}>
      {!isGettingUserLoading ? (
        <>
          <div className="mb-5">
            <Paper
              backgroundColor="white"
              borderColor="white"
              paddingHorizontal={32}
              paddingVertical={14}
            >
              <ConfigProvider
                theme={{
                  components: {
                    Tabs: {
                      /* here is your component tokens */
                      fontSize: 16,
                      colorBgContainer: "#E3332D0D",
                      colorBorderSecondary: "#fffff",
                      colorFillAlter: "#fffff",
                      padding: 24,
                      margin: 0,
                    },
                  },
                }}
              >
                <Tabs
                  type="card"
                  items={items}
                  onChange={onChange}
                  prefixCls="tabContainer"
                  defaultActiveKey={selectedTab}
                />
              </ConfigProvider>
            </Paper>
          </div>
          {selectedTab === "info" ? (
            <UserForm user={data} />
          ) : (
            <div className="mt-4">
              <Paper
                borderColor={COLORS.lightGrey}
                paddingHorizontal={44}
                paddingVertical={24}
              >
                <UserInfo data={data} containerStyle={styles.userInfo} />
              </Paper>
              <div className="mt-4">
                <Table
                  isCheckBoxVisible={false}
                  headers={headers}
                  data={
                    // @ts-ignore
                    balaceData?.results?.map((item: any) =>
                      // @ts-ignore
                      changeBalanceData(item)
                    ) ?? []
                  }
                  // data={[]}
                  isLoading={balanceLoader}
                  // @ts-ignore
                  count={balaceData?.count}
                  // pageSize={IMPORTANT_VARS.TabelDataPageSize}
                />
              </div>
            </div>
          )}
        </>
      ) : (
        <LoadingIndicator color="primary" isFullscreen />
      )}
      {isEditBalance && (
        <EditUserBalance
          isVisible={isEditBalance}
          setIsVisible={setisEditBalance}
          balance={selectedBalance}
        />
      )}
    </div>
  );
}
