/* eslint-disable */

import { Form } from "antd";
import dayjs from "dayjs";
import {
  useAssignBasedShiftMutation,
  useAssignFixedShiftMutation,
  useAssignFlexibleShiftMutation,
} from "apis/services/shift";
import {
  useDeleteLeaveBalancePolicyMutation,
  useGetShiftWorkinghoursQuery,
  usePostLeaveBalancePolicyMutation,
  useUpdateLeaveBalancePolicyMutation,
} from "apis/services/other";

import showSuccessMsg from "utils/showSuccessMsg";
import ModalWrapper from "../ModalWrapper";
import styles from "./styles.module.scss";
import TextInput from "components/inputs/TextInput";
import Button from "components/general/Button";

export default function AssignBalance({
  containerStyle,
  isVisible,
  setIsVisible,
  ItemData,
  tableData,
  settableData,
  detailModalType,
  AddBalance,
  editBalance,
  policyID,
}: any) {
  const [form] = Form.useForm();
  const [postLeaveBalancePolicy] = usePostLeaveBalancePolicyMutation();
  const [updateLeaveBalancePolicy] = useUpdateLeaveBalancePolicyMutation();
  console.log(isVisible, "isVisible");

  const onAddBalance = (values: any) => {
    postLeaveBalancePolicy([values])
      .unwrap()
      .then(() => {
        showSuccessMsg({ msg: "Balance added successfully!" });
      });
  };

  const onEditBalance = (values: any) => {
    updateLeaveBalancePolicy({ data: values, id: ItemData.id })
      .unwrap()
      .then(() => {
        showSuccessMsg({ msg: "Balance edited successfully!" });
      });
  };

  // @ts-ignore
  const onFinish = (values) => {
    const isAdd = detailModalType == "add";
    const formValues = {
      ...values,
      balance: values.balance * 8,
      leave_policy: policyID,
    };

    if (isAdd) onAddBalance(formValues);
    else onEditBalance(formValues);

    setIsVisible(false);
  };

  return (
    <ModalWrapper
      size="large"
      headerTitle="Assign Balance"
      isVisible={isVisible}
      setIsVisible={() => setIsVisible(false)}
      onConfirm={() => form.submit()}
      isFooterHidden
    >
      <div className={`${styles.container} ${containerStyle}`}>
        <div>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            initialValues={
              detailModalType == "add"
                ? {}
                : { ...ItemData, balance: ItemData.balance / 8 }
            }
          >
            <Form.Item
              name="age_from"
              label="Age From"
              rules={[{ required: true }]}
            >
              <TextInput isNumberInput />
            </Form.Item>
            <Form.Item
              name="age_to"
              label="Age To"
              rules={[{ required: true }]}
            >
              <TextInput isNumberInput />
            </Form.Item>
            <Form.Item
              name="years_from"
              label="Years From"
              rules={[{ required: true }]}
            >
              <TextInput isNumberInput />
            </Form.Item>
            <Form.Item
              name="years_to"
              label="Years To"
              rules={[{ required: true }]}
            >
              <TextInput isNumberInput />
            </Form.Item>
            <Form.Item
              name="balance"
              label="Balance"
              rules={[{ required: true }]}
            >
              <TextInput isNumberInput />
            </Form.Item>
            <div className="d-flex gap-2 justify-content-end">
              <Button isOutline onClick={() => setIsVisible(false)}>
                Cancel
              </Button>
              <Button type="submit">Confirm</Button>
            </div>
          </Form>
        </div>
      </div>
    </ModalWrapper>
  );
}
