/* eslint-disable */

import dayjs from "dayjs";
import { Card, Form } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "reducers";
import {
  useCreateRequestMutation,
  useLazyGetPermissionMissingTimeQuery,
} from "apis/services/requests";
import TextInput from "components/inputs/TextInput";
import UserInfo from "components/cards/UserInfo";
import SelectionInput from "components/inputs/SelectionInput";
import DatePickerInput from "components/inputs/DatePickerInput";
import AssigneeSelection from "components/inputs/AssigneeSelection";
import showSuccessMsg from "utils/showSuccessMsg";
import {
  useGetPermissionTypesQuery,
  useGetRequestsSetupQuery,
} from "apis/services/other";
import ModalWrapper from "../../ModalWrapper";
import styles from "../styles.module.scss";
import { useEffect, useState } from "react";
import moment from "moment";
import IMPORTANT_VARS from "constants/ImportantVars";
import Text from "components/general/Text";
import { useParams } from "react-router-dom";
import COLORS from "constants/Colors";
import { RequestsTypes } from "constants/Requests";

export default function Permissions({
  containerStyle,
  isVisible,
  setIsVisible,
  isMultipleAssign,
  isUpdate,
  created_for,
  default_values,
  is_from_calender,
}: any) {
  const userData = useSelector((state: RootState) => state.auth.userData);
  const { id } = useParams();
  const request_created_for = created_for || userData;
  const [missingTimes, setmissingTimes] = useState([]);
  const [selectedMissingTime, setselectedMissingTime] = useState({});

  const [form] = Form.useForm();
  const [getDateMissingTime] = useLazyGetPermissionMissingTimeQuery();

  const { data: permissionTypes, isLoading: isPermissionTypesLoading } =
    useGetRequestsSetupQuery({
      group: "permission",
      page_size: IMPORTANT_VARS.TabelDataPageSize,
    });
  const permissionOptions = permissionTypes?.results?.map((item: any) => {
    return { label: item.name, value: item.id };
  });
  const [createRequest] = useCreateRequestMutation();

  const onFinish = (values: {
    leave_type: number;
    employee_ids: any;
    date?: string;
    from_time?: string;
    to_time?: string;
  }) => {
    const data = {
      ...values,
      method: isUpdate ? "PATCH" : "POST",
      URL_request_type: "business_permission_requests",
      request_type: RequestsTypes.business_permission_requests,
      created_for: values?.employee_ids?.value ?? request_created_for?.id,
      // @ts-ignore
      missing_time: selectedMissingTime?.id,
      attachments: [],
      delegated_to: [],
    };
    if (values?.date) {
      data.date = dayjs(data.date).format("YYYY-MM-DD");
    }
    if (values?.from_time) {
      data.from_time += ".00";
      data.to_time += ".00";
    }
    createRequest(data)
      .unwrap()
      .then(() => {
        showSuccessMsg({ msg: "Request created successfully!" });
        setIsVisible(false);
      });
  };

  const [customEndDateError, setcustomEndDateError] = useState(false);

  const handleGetMissingTime = (date: any, userID?: any) => {
    let valu = {
      date: date,
      checkin__checked_staff: userID || request_created_for.id || id,
    };

    getDateMissingTime(valu)
      .unwrap()
      .then((res) => {
        setmissingTimes(res?.results);
      });
  };
  const handleMissingTimeSelection = (item: any) => {
    setselectedMissingTime(item);
    // ! This following couple of lines will only work if the DatePickerInput component uses the value prop, but this will ruin the rest of the date inputs.
    // form.setFieldValue(
    //   "from_time",
    //   dayjs(item?.should_checked_in_time, "HH:mm:ss")
    // );
    // form.setFieldValue(
    //   "to_time",
    //   dayjs(item?.should_checked_out_time, "HH:mm:ss")
    // );
  };

  const handleDisabledFields = (changedFields: any, allValues: any) => {
    if (allValues.from_time && "to_time" in changedFields) {
      const start_date = moment(allValues.from_time, "hh:mm:ss");
      const end_date = moment(changedFields.to_time, "hh:mm:ss");
      const isAfterStart = end_date?.isAfter(start_date);
      setcustomEndDateError(!isAfterStart);
    }
    if ("date" in changedFields) {
      handleGetMissingTime(changedFields?.date, allValues?.employee_ids?.value);
    }
    if ("employee_ids" in changedFields && allValues?.date) {
      handleGetMissingTime(allValues?.date, changedFields?.employee_ids?.value);
    }
  };

  const isDefaultDate = () => {
    if (default_values?.disabled && default_values?.data?.date) {
      return form.setFieldValue(
        "date",
        dayjs(default_values.data.date, "YYYY-MM-DD")
      );
    }
    return undefined;
  };

  useEffect(() => {
    if (
      default_values?.disabled &&
      default_values?.data?.date &&
      is_from_calender
    ) {
      handleGetMissingTime(default_values?.data?.date);
    }
  }, [default_values]);
  return (
    <ModalWrapper
      size="large"
      headerTitle="Request Permissions"
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      onConfirm={() => form.submit()}
      // @ts-ignore
      // isBtnDisabled={!selectedMissingTime?.id}
    >
      <div className={`${styles.container} ${containerStyle}`}>
        <div>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            onValuesChange={handleDisabledFields}
          >
            {!isMultipleAssign ? (
              <div className={styles.memberContent}>
                <UserInfo data={request_created_for} />
              </div>
            ) : (
              <Form.Item
                name="employee_ids"
                label="Assignees Members"
                rules={[{ required: true }]}
              >
                <AssigneeSelection isMulti={false} />
              </Form.Item>
            )}

            <Form.Item
              name="leave_type"
              label="Permissions Type"
              rules={[{ required: true }]}
            >
              <SelectionInput
                options={permissionOptions || []}
                disabled={isPermissionTypesLoading}
              />
            </Form.Item>

            <Form.Item
              name="date"
              label="Select Day"
              rules={[{ required: true }]}
              initialValue={isDefaultDate()}
            >
              <DatePickerInput disabled={default_values?.disabled} />
            </Form.Item>

            <div className="d-flex gap-2 mb-3">
              {missingTimes?.map((item: any) => {
                // @ts-ignore
                const isSelectedCard = selectedMissingTime?.id == item?.id;
                return (
                  <>
                    {!item.request && (
                      <Card
                        hoverable
                        className="w-50"
                        style={{
                          border: isSelectedCard
                            ? `2px ${COLORS.primary} solid`
                            : "1px solid #f0f0f0 ",
                        }}
                        onClick={() => handleMissingTimeSelection(item)}
                      >
                        <div className="d-flex align-items-center gap-2">
                          <Text className={styles.bold}>Date:</Text>
                          <Text> {item.date}</Text>
                        </div>
                        <div className="d-flex align-items-center gap-2">
                          <Text className={styles.bold}>Checked in time:</Text>
                          <Text>{item.checked_in_time}</Text>
                        </div>
                        <div className="d-flex align-items-center gap-2">
                          <Text className={styles.bold}>Checked out time:</Text>
                          <Text>{item.checked_out_time}</Text>
                        </div>
                        <div className="d-flex align-items-center gap-2">
                          <Text className={styles.bold}>
                            Should checked in time:
                          </Text>
                          <Text>{item.should_checked_in_time}</Text>
                        </div>
                        <div className="d-flex align-items-center gap-2">
                          <Text className={styles.bold}>
                            Should checked out time:
                          </Text>
                          <Text>{item.should_checked_out_time}</Text>
                        </div>
                      </Card>
                    )}
                  </>
                );
              })}
            </div>

            <Form.Item
              name="from_time"
              label="Time From:"
              rules={[{ required: true }]}
              style={{ display: "inline-block", width: "calc(50% - 8px)" }}
            >
              <DatePickerInput picker="time" />
            </Form.Item>

            <Form.Item
              name="to_time"
              label="Time To:"
              rules={[
                { required: true },
                {
                  validator: (_, value) => {
                    if (!customEndDateError) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject("Time to must be after time from");
                    }
                  },
                },
              ]}
              style={{
                display: "inline-block",
                width: "calc(50% - 8px)",
                margin: "0 8px",
              }}
            >
              <DatePickerInput picker="time" onChange={(x) => console.log(x)} />
            </Form.Item>

            <Form.Item name="reason">
              <TextInput type="textarea" placeholder="Reason For leave" />
            </Form.Item>
          </Form>
        </div>
      </div>
    </ModalWrapper>
  );
}

const cc = {
  attachments: [],
  created_for: 101,
  date: "2024-04-24",
  delegated_to: [],
  from_time: "02:17:58.00",
  method: "POST",
  missing_time: 14,
  request_type: "business_permission_requests",
  template_id: 24,
  to_time: "11:28:34.00",
};
