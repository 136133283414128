/* eslint-disable */

import { Form } from "antd";
import TextInput from "components/inputs/TextInput";
import showSuccessMsg from "utils/showSuccessMsg";
import { useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import ModalWrapper from "../ModalWrapper";
import { useUpdateUserBalanceUpdateMutation } from "apis/services/staff";

export default function EditUserBalance({
  containerStyle,
  isVisible,
  setIsVisible,
  balance,
}: any) {
  const params = useParams();
  const userId = params?.id;
  const [form] = Form.useForm();

  console.log(balance, "balanceeee");

  //   const is_editing_myself =
  //     String(userData?.id) === id || location.pathname.includes("my-profile");

  // const [editMemberOvertime] = useEditNewStaffOvertimesMutation();
  const [editUserBalance] = useUpdateUserBalanceUpdateMutation();

  const onFinish = (values: any) => {
    const data = { ...values, id: balance.id };
    editUserBalance(data)
      .unwrap()
      .then(() => {
        showSuccessMsg({ msg: "Balance edited successfully!" });
        setIsVisible(false);
      });
  };

  return (
    <ModalWrapper
      size="large"
      headerTitle="Edit Overtime"
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      onConfirm={() => form.submit()}
    >
      <div className={`w-100 ${containerStyle}`}>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          initialValues={balance}
        >
          <Form.Item
            name="leave_policy_name"
            label="Leave Type"
            rules={[{ required: true }]}
          >
            <TextInput disabled />
          </Form.Item>

          <Form.Item
            name="created_at"
            label="Date"
            rules={[{ required: true }]}
          >
            <TextInput disabled />
          </Form.Item>
          <Form.Item name="total" label="Alloted">
            <TextInput />
          </Form.Item>
          <Form.Item name="used" label="Used">
            <TextInput />
          </Form.Item>
          <Form.Item name="balance" label="Balance">
            <TextInput />
          </Form.Item>
        </Form>
      </div>
    </ModalWrapper>
  );
}
