/* eslint-disable */

import { useState } from "react";
import SecondaryPageHeader from "components/general/SecondaryPageHeader";
import Button from "components/general/Button";
import Text from "components/general/Text";
import CreateRequest from "components/modals/requests";
import useGetUserInfo from "hooks/useGetUserInfo";
import styles from "./styles.module.scss";

const RequestsTypes = [
  {
    title: "Leaves",
    type: "leave",
  },
  {
    title: "Business Permissions",
    type: "permissions",
  },
  {
    title: "Mission",
    type: "mission",
  },
  {
    title: "Transfer",
    type: "transfer",
  },
  {
    title: "Business trip",
    type: "trip",
  },
];

export default function CreateNewRequest() {
  const [isDetailsShown, setIsDetailsShown] = useState<string | null>(null);
  const { role } = useGetUserInfo();
  const shoudAddTransfer = ["admin", "manager"].includes(role!);

  return (
    <div className={styles.container}>
      <SecondaryPageHeader
        title="Apply New request"
        SubTitleComponent={
          <Text className="d-block">Select the request type</Text>
        }
        RightComponent={null}
      />

      <section className="my-4">
        {RequestsTypes.map((item) => (
          <Button
            key={item.title}
            btnClassName={`${styles.btnStyle} mb-3`}
            onClick={() => setIsDetailsShown(item.type)}
            disabled={!shoudAddTransfer && ["transfer"].includes(item.type)}
          >
            {item?.title}
          </Button>
        ))}
      </section>

      <CreateRequest
        isVisible={!!isDetailsShown}
        setIsVisible={() => setIsDetailsShown(null)}
        type={isDetailsShown}
        isMultipleAssign
      />
    </div>
  );
}
