/* eslint-disable */
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useGetStaffMemberInfoQuery } from "apis/services/staff";
import COLORS from "constants/Colors";
import Paper from "components/general/PaperComponent";
// import SeparatedRowElements from "components/general/SeparatedRowElements";
// import InfoRow from "components/general/InfoRow";
import UserInfo from "components/cards/UserInfo";
import SecondaryPageHeader from "components/general/SecondaryPageHeader";
import FilterButton from "components/general/FilterButton";
import CalendarRow from "components/general/CalendarRow";
import {
  // useGetMyEmployeeShiftsConflictsQuery,
  useGetMyEmployeeShiftsQuery,
  useGetMyShiftsQuery,
} from "apis/services/shift";
// import moment from "moment";
// import LoadingIndicator from "components/general/LoadingIndicator";
import { useGetPublicHolidaysQuery } from "apis/services/other";
import useGetUserInfo from "hooks/useGetUserInfo";
import moment from "moment";
import { Pagination, Spin } from "antd";
import { useState } from "react";
import Permissions from "components/modals/requests/Permissions";
import styles from "./styles.module.scss";
import Button from "components/general/Button";

export default function StaffMemberCalender() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const [isRequestVisible, setisRequestVisible] = useState(false);
  const [selectedCalendarDay, setselectedCalendarDay] = useState({});
  const { userInfo: userInfo, role } = useGetUserInfo();

  const isEmployee = role === "employee";
  const { data: staffInfo } = useGetStaffMemberInfoQuery(id!, {
    skip: isEmployee,
  });
  const data = isEmployee ? userInfo : staffInfo;

  const useGetShifts = isEmployee
    ? useGetMyShiftsQuery
    : useGetMyEmployeeShiftsQuery;
  const { data: shifts, isFetching: isEmployeeShiftsLoading } = useGetShifts(
    {
      page: searchParams.get("page") ?? 1,
      employee: id ?? "",
      search: searchParams.get("search") ?? "",
      status: searchParams.get("status") ?? "",
      location: searchParams.get("location") ?? "",
      department: searchParams.get("department") ?? "",
      shift_type: searchParams.get("shift") ?? "",
      start_date: searchParams.get("startDate") ?? undefined,
      end_date: searchParams.get("endDate") ?? undefined,
      page_size: 20,
    }
    // { skip: !role }
  );

  const { data: publicHolidays, isLoading: isPublicHolidayLoading } =
    useGetPublicHolidaysQuery({
      page_size: 100,
    });

  function checkIfShiftIsPublicHoliday(date: any) {
    const result = {
      isHoliday: false,
      name: "",
    };
    publicHolidays?.results.forEach((holiday: any) => {
      if (holiday.date === date) {
        result.isHoliday = true;
        result.name = holiday.name;
      }
    });

    return result;
  }

  const onPageChange = (page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  const handleIsPermission = (checkin_item: any) => {
    if (!checkin_item) return false;
    const tolerance_after_start =
      checkin_item?.working_hour?.tolerance_after_start;
    const tolerance_before_end =
      checkin_item?.working_hour?.tolerance_before_end;

    const hasExceedStartTime = moment(checkin_item?.checkin?.checkin_time)
      .subtract(tolerance_after_start, "minute")
      .isAfter(
        moment(
          `${checkin_item?.date} ${checkin_item?.start_time}`,
          "YYYY-MM-DD HH:mm:ss"
        )
      );

    const hasExceedEndTime = moment(checkin_item?.checkin?.checkout_time)
      .add(tolerance_before_end, "minute")
      .isBefore(
        moment(
          `${checkin_item?.date} ${checkin_item?.end_time}`,
          "YYYY-MM-DD HH:mm:ss"
        )
      );

    return hasExceedEndTime || hasExceedStartTime;
  };

  const getcalenderStatus = (shift: any) => {
    const statusEnum = {
      Off: "off",
      "Not In": "absent",
      conflict: "conflict",
      permission: "permission",
      success: "success",
    };
    const isPermission = handleIsPermission(shift);
    if (shift.attended && shift.status === "Checked Out")
      return statusEnum.success;
    if (shift.status === "Off") return statusEnum.Off;
    if (shift.status === "Not In") return statusEnum["Not In"];
    if (shift?.conflict?.id) return statusEnum.conflict;
    if (isPermission) return statusEnum.permission;

    // @ts-ignore
    return statusEnum[shift?.checkin?.status] || statusEnum.success;
  };
  const workingHourValidator = (shift: any) => {
    const { start_time, end_time } = shift;
    const workingHour = String(
      moment(end_time, "HH:mm:ss").diff(moment(start_time, "HH:mm:ss"), "hours")
    );
    if (workingHour.includes("-")) {
      return "Invalid";
    }
    return workingHour;
  };
  const handleActionClick = (item: any) => {
    setisRequestVisible(true);
    setselectedCalendarDay(item);
  };

  const hanldeActionClose = () => {
    setisRequestVisible(false);
    setselectedCalendarDay({});
  };
  console.log(shifts?.results[0], "Outside");

  return (
    <div className={styles.staffMemberInfoContainer}>
      <Paper
        backgroundColor={COLORS.lightDangerBackground}
        borderColor={COLORS.lightDangerBorder}
        paddingVertical={20}
        paddingHorizontal={32}
      >
        <UserInfo data={data} />
      </Paper>

      {/* <div className="mt-4">
        <SeparatedRowElements>
          {INFODATA.map((item) => (
            <InfoRow
              key={item.title}
              title={`${item.title}:`}
              info={`${item.info} days`}
              infoFontSize={18}
              infoFontWeight="500"
              containerStyle="py-2"
            />
          ))}
        </SeparatedRowElements>
      </div> */}
      <div className="mt-4">
        <Paper
          backgroundColor={COLORS.light}
          borderColor={COLORS.lightGrey}
          paddingVertical={20}
          paddingHorizontal={32}
        >
          <SecondaryPageHeader
            title="Calender"
            RightComponent={
              <div className="d-flex flex-row">
                <div className="mx-2">
                  <Button onClick={() => navigate("/requests/team%20Requests/create-new-request")}>
                    Add request
                  </Button>
                </div>
                <div className="mx-2">
                  <FilterButton onClick={() => {}} filtersToHide={[]} />
                </div>
              </div>
            }
          />
          {isEmployeeShiftsLoading || isPublicHolidayLoading ? (
            <div className="d-flex justify-content-center">
              <Spin />
            </div>
          ) : (
            <>
              {/* @ts-ignore */}
              {shifts?.results?.map((item) => {
                const calendarStatus = getcalenderStatus(item);
                return (
                  <CalendarRow
                    // onClickHandler={() => setleaveModalshown(true)}
                    showActionButton={calendarStatus === "permission"}
                    isPublicHoliday={checkIfShiftIsPublicHoliday(item.date)}
                    onClickHandler={() => handleActionClick(item)}
                    dataItem={{
                      id: item.id,
                      // @ts-ignore
                      fixed_shift_name: item.fixed_shift_name,
                      shiftType: item.shift_type,
                      checkin: item.checkin,
                      // @ts-ignore
                      state: calendarStatus,
                      date: item.date,
                      start_time: item.start_time,
                      working_hour: workingHourValidator(item),
                    }}
                  />
                );
              })}
              {shifts?.count && (
                <Pagination
                  className="text-center my-3"
                  pageSize={20}
                  total={shifts?.count}
                  current={Number(searchParams.get("page") ?? 1)}
                  defaultCurrent={Number(searchParams.get("page") ?? 1)}
                  onChange={(value: number) => onPageChange(value)}
                  showSizeChanger={false}
                />
              )}
            </>
          )}
        </Paper>
        {isRequestVisible && (
          <Permissions
            isVisible={isRequestVisible}
            setIsVisible={hanldeActionClose}
            created_for={data}
            default_values={{ disabled: true, data: selectedCalendarDay }}
            is_from_calender
          />
        )}
      </div>
    </div>
  );
}
