/* eslint-disable */
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import Text from "components/general/Text";
import Paper from "components/general/PaperComponent";
import ManageRequest from "components/modals/ManageRequest";
import ConflictLocation from "components/modals/ConflictLocation";
import COLORS from "constants/Colors";
import {
  useGetNotificationsQuery,
  useGetSpecificStaffFromAllStaffQuery,
} from "apis/services/staff";
import { useLazyGetRequestQuery } from "apis/services/requests";
import { useLazyGetConflictDetialsQuery } from "apis/services/shift";
import { useGetLocationQuery } from "apis/services/locations";
import ActivityCard from "components/cards/ActivityCard";
import useGetUserInfo from "hooks/useGetUserInfo";
import styles from "./styles.module.scss";
import dayjs from "dayjs";

export default function Notifications() {
  const [isModalShown, setIsModalShown] = useState(false);
  const [isConflictModalVisible, setIsConflictModalVisible] = useState(false);
  const [conflictDateAndTime, setConflictDateAndTime] = useState({});
  const navigate = useNavigate();

  const { userInfo } = useGetUserInfo();

  const { data } = useGetNotificationsQuery({});

  const [trigger, { data: requestData }] = useLazyGetRequestQuery();

  const [getConflictDetails, { data: conflictData, isLoading }] =
    useLazyGetConflictDetialsQuery();

  const { data: staffDetails, isLoading: getStaffDeatilsLoading } =
    useGetSpecificStaffFromAllStaffQuery(Number(conflictData?.employee), {
      skip: !conflictData || isLoading,
    });

  const { data: currentLocationData, isLoading: currentLocationLoading } =
    useGetLocationQuery(
      { id: conflictData?.location },
      {
        skip: !conflictData?.location || isLoading,
      }
    );

  useEffect(() => {
    if (
      !currentLocationLoading &&
      !getStaffDeatilsLoading &&
      currentLocationData &&
      staffDetails
    ) {
      setIsConflictModalVisible(true);
    }
  }, [
    currentLocationLoading,
    getStaffDeatilsLoading,
    staffDetails,
    currentLocationData,
  ]);

  const handleRedirectNotification = (notification: any) => {
    if (
      notification?.extra?.id &&
      !["delegation", "conflict", "new_location"].includes(
        notification?.slug
      ) &&
      !["calendar", "shift"].includes(notification?.extra?.slug)
    ) {
      trigger(notification?.extra?.id).then(() => {
        setIsModalShown(true);
      });
    } else if (notification?.extra?.slug === "calendar") {
      navigate(`/staff/${userInfo?.id}/calender`);
    } else if (notification?.extra?.slug === "shift") {
      navigate("/shifts");
    } else if (notification?.extra?.slug === "conflict") {
      getConflictDetails({ id: notification?.extra?.id })
        .unwrap()
        .then((res) => {
          // @ts-ignore
          if (res && res?.id === notification?.extra?.id) {
            setIsConflictModalVisible(true);
          } else {
            setConflictDateAndTime({
              date: notification?.date,
              time: notification?.time,
            });
          }
        });
    }
  };

  const groupNotificationsByDate = useCallback((notifications: any[]) => {
    return notifications?.reduce((groups: any, item: any) => {
      const date = dayjs(item.date);
      const today = dayjs();

      let group = "Earlier";
      if (date.isSame(today, "day")) {
        group = "Today";
      } else if (date.isAfter(today.subtract(7, "day"))) {
        group = "Last Week";
      }
      if (!groups[group]) {
        groups[group] = [];
      }
      groups[group].push(item);
      return groups;
    }, {});
  }, []);

  return (
    <>
      <div className={`${styles.container} p-5`}>
        <div className="mb-3">
          <Text className={styles.bold}>Notifications</Text>
        </div>
        <Paper
          backgroundColor={COLORS.light}
          borderColor={COLORS.lightGrey}
          paddingVertical={20}
          paddingHorizontal={32}
        >
          {/* @ts-ignore */}
          {data?.results &&
            /* @ts-ignore */
            Object.entries(groupNotificationsByDate(data.results)).map(
              // @ts-ignore
              ([dateGroup, items]: [string, any[]]) => (
                <div key={dateGroup} className="mb-5">
                  <Text
                    className={`d-flex ms-2 mb-3 ${styles.header} `}
                    // style={{ fontSize: 22, fontWeight: 600 }}
                  >
                    {dateGroup}
                  </Text>
                  {items.map((item) => (
                    <div
                      key={item.id}
                      className="mb-3"
                      onClick={() => handleRedirectNotification(item)}
                      onKeyDown={() => handleRedirectNotification(item)}
                      role="button"
                      title="notification card"
                      tabIndex={0}
                    >
                      <ActivityCard item={item} isNotificaion />
                    </div>
                  ))}
                </div>
              )
            )}
        </Paper>
      </div>

      {requestData && (
        <ManageRequest
          request={requestData}
          transferAllocations={requestData?.transfer_allocations}
          setIsVisible={setIsModalShown}
          isVisible={isModalShown}
        />
      )}

      <ConflictLocation
        user={{
          conflict: conflictData,
          employee: staffDetails,
          currentLocation: currentLocationData,
          dateAndTime: conflictDateAndTime,
        }}
        setIsVisible={setIsConflictModalVisible}
        isVisible={isConflictModalVisible}
      />
    </>
  );
}
