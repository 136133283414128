import TabsLayout from "components/general/TabsLayout";
import { ShiftType, ShiftTypeName } from "constants/ShiftType";
import useGetUserInfo from "hooks/useGetUserInfo";
import {
  useGetShiftsStatisticsQuery,
  useGetStaffShiftsStatisticsQuery,
} from "apis/services/shift";
import BulkUploadLog from "components/pages/shifts/BulkUploadLog";
import { useSearchParams } from "react-router-dom";
import moment from "moment";
import Fixed from "../../components/pages/shifts/Fixed";
import BasedSetup from "../../components/pages/shifts/BasedSetup";
import Flexible from "../../components/pages/shifts/Flexible";
import styles from "./styles.module.scss";

export default function Shifts() {
  const [searchParams] = useSearchParams();
  const { role } = useGetUserInfo();
  const getStatistics =
    role === "employee"
      ? useGetShiftsStatisticsQuery
      : useGetStaffShiftsStatisticsQuery;
  // @ts-ignore
  const { data: statistics, isLoading: isStatisticLoading } = getStatistics({
    start_date:
      searchParams.get("startDate") ?? moment(new Date()).format("YYYY-MM-DD"),
    end_date:
      searchParams.get("endDate") ??
      moment(new Date()).add(1, "day").format("YYYY-MM-DD"),
  });
  const ShifTabs = [
    {
      label: ShiftTypeName[ShiftType.Fixed],
      key: ShiftType.Fixed.toString(),
      children: (
        <Fixed statisticts={statistics} isLoading={isStatisticLoading} />
      ),
    },
    {
      label: ShiftTypeName[ShiftType.Based],
      key: ShiftType.Based.toString(),
      children: (
        <BasedSetup statisticts={statistics} isLoading={isStatisticLoading} />
      ),
    },
    {
      label: ShiftTypeName[ShiftType.Flexible],
      key: ShiftType.Flexible.toString(),
      children: (
        <Flexible statisticts={statistics} isLoading={isStatisticLoading} />
      ),
    },
    {
      label: "Bulk Upload Log",
      key: "logs",
      children: (
        <BulkUploadLog statisticts={statistics} isLoading={isStatisticLoading} />
      ),
    },
  ];

  return (
    <div className={styles.container}>
      <TabsLayout tabs={ShifTabs} />
    </div>
  );
}
