import ModalWrapper from "components/modals/ModalWrapper";
import { Form } from "antd";
import TextInput from "components/inputs/TextInput";
import showSuccessMsg from "utils/showSuccessMsg";
import {
  useAddFingerprintMutation,
  useEditFingerprintMutation,
} from "apis/services/locations";
import RadioGroupInput from "components/inputs/RadioGroupInput";

export default function AddNewFingerprintModal({
  isVisible,
  setIsVisible,
  isUpdate,
  initialValues,
}: any) {
  const [form] = Form.useForm();

  const [addFingerprint, { isLoading: isAddLocationLoading }] =
    useAddFingerprintMutation();
  const [editFingerprint, { isLoading: isEditLocationLoading }] =
    useEditFingerprintMutation();

  const editFingerFN = (values: any) => {
    const request_values = {
      ...values,
      id: initialValues?.id,
    };
    editFingerprint(request_values)
      .unwrap()
      .then(() => {
        setIsVisible(false);
        form.resetFields();
        showSuccessMsg({ msg: "Edited location successfully!" });
      });
  };

  const addFingerFN = (values: any) => {
    const request_values = {
      ...values,
      location: {
        zone: {
          name: "zone name",
        },
        name: "unspecified",
        address: values.location,
      },
    };
    console.log({ request_values });

    addFingerprint(request_values)
      .unwrap()
      .then(() => {
        setIsVisible(false);
        form.resetFields();
        showSuccessMsg({ msg: "Added fingerprint successfully!" });
      });
  };

  const submitForm = () => {
    form.submit();
  };

  const yesNoOptions = [
    { label: "Yes", value: 1 },
    { label: "No", value: 0 },
  ];

  return (
    <ModalWrapper
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      size="large"
      headerTitle={isUpdate ? "Edit Fingerprint" : "Add New Fingerprint"}
      onConfirm={submitForm}
      isLoading={isAddLocationLoading || isEditLocationLoading}
    >
      <Form
        form={form}
        onFinish={isUpdate ? editFingerFN : addFingerFN}
        initialValues={
          isUpdate
            ? {
                ...initialValues,
                location: initialValues.location.address || "",
                active: initialValues.active ? 1 : 0,
              }
            : {}
        }
        layout="vertical"
        scrollToFirstError
        className="w-100"
      >
        <Form.Item
          name="device_number"
          label="Device number"
          rules={[{ required: true }]}
        >
          <TextInput disabled={isUpdate} />
        </Form.Item>

        <Form.Item
          name="location"
          label="Location"
          rules={[{ required: true }]}
        >
          <TextInput />
        </Form.Item>

        <Form.Item
          name="active"
          label="Account status"
          rules={[{ required: true }, { type: "number" }]}
        >
          {/* @ts-ignore */}
          <RadioGroupInput options={yesNoOptions} />
        </Form.Item>
      </Form>
    </ModalWrapper>
  );
}
