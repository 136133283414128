/* eslint-disable */
import moment from "moment";
import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import SeparatedRowElements from "components/general/SeparatedRowElements";
import InfoRow from "components/general/InfoRow";
import Table from "components/general/Table";
import UserInfo from "components/cards/UserInfo";
import {
  useGetMyEmployeeShiftsQuery,
  useGetMyShiftsQuery,
} from "apis/services/shift";
import { AllStaffShiftsProps } from "apis/types/shift";
import { ShiftType } from "constants/ShiftType";
import formatTime from "utils/formatDateTime";
import IMPORTANT_VARS from "constants/ImportantVars";
import useGetUserInfo from "hooks/useGetUserInfo";
import StaffAction from "./StaffAction";
import TabHeader from "./TabHeader";
import styles from "./styles.module.scss";

export default function Fixed({
  statisticts,
  isLoading,
}: {
  statisticts?: any;
  isLoading?: boolean;
}) {
  const [searchParams] = useSearchParams();

  const { role } = useGetUserInfo();

  const requestBasedOnRole =
    role === "employee" ? useGetMyShiftsQuery : useGetMyEmployeeShiftsQuery;

  const { data, isFetching: isEmployeeShiftsLoading } = requestBasedOnRole(
    {
      shift_type: ShiftType.Fixed,
      page: searchParams.get("page") ?? "1",
      search: searchParams.get("search") ?? "",
      start_date:
        searchParams.get("startDate") ??
        moment(new Date()).format("YYYY-MM-DD"),
      end_date: searchParams.get("endDate") ?? undefined,
      page_size: IMPORTANT_VARS.TabelDataPageSize,
    },
    { skip: !role }
  );

  const INFOS = [
    {
      title: "Present Days",
      info: statisticts?.present ?? 0,
    },
    {
      title: "Off",
      info: statisticts?.off ?? 0,
    },
    {
      title: "Leaves",
      info: statisticts?.leaves ?? 0,
    },
    {
      title: "Absent",
      info: statisticts?.absent ?? 0,
    },
  ];

  const isWorkDay = (item: AllStaffShiftsProps) =>
    item?.specification === "work";

  const changeShiftData = (item: any): any => ({
    id: String(item.id),
    rowData: [
      {
        type: "element",
        dataItem: <UserInfo data={item?.employee} isTableCol />,
      },
      {
        type: "text",
        dataItem: `${item?.fixed_shift_name}`,
      },
      {
        type: "text",
        dataItem: isWorkDay(item) ? item?.specification : "off",
      },
      {
        type: "text",
        dataItem: isWorkDay(item) ? formatTime(item.start_time) : "off",
      },
      {
        type: "text",
        dataItem: moment(item.date).format("DD-MM-YYYY"),
      },
      {
        type: "text",
        dataItem: isWorkDay(item)
          ? `${Math.abs(
              dayjs(item.end_time, "hh:mm:ss").diff(
                dayjs(item.start_time, "hh:mm:ss"),
                "hour"
              )
            )} Hours`
          : "off",
      },
      {
        type: "element",
        dataItem: (
          <StaffAction
            shift={{
              ...item,
              // @ts-ignore
              working_hour: `${Math.abs(
                dayjs(item.end_time, "hh:mm:ss").diff(
                  dayjs(item.start_time, "hh:mm:ss"),
                  "hour"
                )
              )}`,
              // @ts-ignore
              template_id: `${item?.fixed_shift_name} (${formatTime(
                item.start_time
              )} - ${formatTime(item.end_time)})`,
            }}
            employee={item.employee}
            assignType="fixed"
          />
        ),
      },
    ],
  });

  return (
    <div className={styles.container}>
      <TabHeader shifType={ShiftType.Fixed} />

      <div className="my-4">
        <SeparatedRowElements>
          {INFOS.map((item) => (
            <InfoRow
              key={item.title}
              title={item.title}
              info={`${item.info}`}
              isReverse
              infoFontSize={28}
              infoFontWeight="500"
            />
          ))}
        </SeparatedRowElements>
      </div>

      <div className="my-4">
        <Table
          isCheckBoxVisible
          showSearchFilter
          isActionBtnVisible={role && role !== "employee"}
          headers={[
            "Members ID",
            "Shift Name",
            "Status",
            "Starting Time",
            "Date",
            "Working Hours",
            "",
          ]}
          data={
            data?.results.map((item: AllStaffShiftsProps) =>
              changeShiftData(item)
            ) ?? []
          }
          isLoading={isEmployeeShiftsLoading || isLoading}
          count={data?.count}
          pageSize={IMPORTANT_VARS.TabelDataPageSize}
        />
      </div>
    </div>
  );
}
