/* eslint-disable */
import { useSearchParams } from "react-router-dom";
import SeparatedRowElements from "components/general/SeparatedRowElements";
import Button from "components/general/Button";
import Text from "components/general/Text";
import DateFilterPaper from "components/general/DateFilterPaper";
import InfoRow from "components/general/InfoRow";
import Image from "components/general/Image";
import CHECK_OUT_ICON from "assets/icons/scan.svg";
import Table from "components/general/Table";
import moment from "moment";
import { AllStaffShiftsProps } from "apis/types/shift";
import { TableItemProps } from "components/general/Table/types";
import useTimeElapsed from "hooks/useTimerElapsed";
import { useCheckinForMySelfMutation } from "apis/services/checkin";
import StatusView from "components/pages/home/status-view";
import UserInfo from "components/cards/UserInfo";
import IMPORTANT_VARS from "constants/ImportantVars";
import {
  // useGetMyEmployeeShiftsConflictsQuery,
  useGetMyEmployeeShiftsQuery,
  useGetMyShiftsQuery,
  useGetShiftsStatisticsQuery,
  useGetStaffShiftsStatisticsQuery,
} from "apis/services/shift";
import { useState } from "react";
import useGetUserInfo from "hooks/useGetUserInfo";
import DateFilter from "components/modals/DateFilter";
import Icon from "components/general/Icon";
import styles from "./styles.module.scss";

export default function Home() {
  const [searchParams] = useSearchParams();
  const { userInfo: userData, role } = useGetUserInfo();
  const [isDateFilterVisible, setIsDateFilterVisible] = useState(false);

  const useGetShifts =
    role === "employee" ? useGetMyShiftsQuery : useGetMyEmployeeShiftsQuery;

  const getStatistics =
    role === "employee"
      ? useGetShiftsStatisticsQuery
      : useGetStaffShiftsStatisticsQuery;
  // @ts-ignore
  const { data: statistics, isLoading: isStatisticLoading } = getStatistics(
    {
      start_date:
        searchParams.get("summaryStartDate") ??
        moment(new Date()).format("YYYY-MM-DD"),
      end_date:
        searchParams.get("summaryEndDate") ??
        moment(new Date()).add(1, "day").format("YYYY-MM-DD"),
    },
    { skip: !role }
  );

  const { data, isFetching: isEmployeeShiftsLoading } = useGetShifts(
    {
      page: searchParams.get("page") ?? 1,
      search: searchParams.get("search") ?? "",
      status: searchParams.get("status") ?? "",
      location: searchParams.get("location") ?? "",
      department: searchParams.get("department") ?? "",
      shift_type: searchParams.get("shift") ?? "",
      start_date:
        searchParams.get("startDate") ??
        moment(new Date()).format("YYYY-MM-DD"),
      end_date:
        searchParams.get("endDate") ??
        moment(new Date()).add(1, "day").format("YYYY-MM-DD"),
      page_size: IMPORTANT_VARS.TabelDataPageSize,
    },
    { skip: !role }
  );

  const checkinTime = userData?.current_day_checkin?.checkin_time;
  const checkoutTime = userData?.current_day_checkin?.checkout_time;

  const isCheckedIn = Boolean(checkinTime);
  const isCheckedOut = Boolean(checkoutTime);

  const formateDate = (date: Date | string | null, format = "hh:mm A") =>
    date ? moment(date).format(format) : "-";

  const checkInTimeDisplayText = formateDate(checkinTime!);

  const timer = useTimeElapsed(checkinTime!);

  const isNeedToCheckout = isCheckedIn && !isCheckedOut;

  const [checkinForMySelf] = useCheckinForMySelfMutation();

  const onCheckInOut = async () => {
    await navigator.geolocation.getCurrentPosition((position) =>
      checkinForMySelf(position.coords)
    );
  };

  const changeStaffData = (item: AllStaffShiftsProps): TableItemProps => ({
    id: String(item.id),
    rowData: [
      {
        type: "element",
        dataItem: <UserInfo data={item.employee} isTableCol />,
      },
      { type: "text", dataItem: item?.employee?.department?.name ?? "-" },
      {
        type: "text",
        dataItem: formateDate(
          item?.date || item?.checkin?.checkin_time,
          "DD-MM-YYYY"
        ),
      },
      {
        type: "text",
        dataItem: formateDate(
          item?.checkin?.checkin_time,
          "DD-MM-YYYY hh:mm A"
        ),
      },
      {
        type: "text",
        dataItem: formateDate(
          item?.checkin?.checkout_time,
          "DD-MM-YYYY hh:mm A"
        ),
      },
      {
        type: "text",
        dataItem: `${item?.overtime?.total_overtime ?? 0} h`,
      },
      {
        type: "element",
        dataItem: <StatusView handleClick={() => {}} checkin={item} />,
      },
    ],
  });

  return (
    <div className={styles.container}>
      <SeparatedRowElements>
        <div className="d-flex align-items-center gap-3">
          <Button
            suffix={<Image src={CHECK_OUT_ICON} />}
            onClick={() => onCheckInOut()}
          >
            {!isNeedToCheckout ? "Check-In" : "Check-Out"}
          </Button>
          {isNeedToCheckout && (
            <Text>{`Checked In at ${checkInTimeDisplayText}`}</Text>
          )}
        </div>

        {isNeedToCheckout && (
          <div className="d-flex align-items-center justify-content-center">
            <Text>{timer}</Text>
          </div>
        )}

        <div className="d-flex align-items-center justify-content-center">
          <Text>
            Location: {userData?.assigned_locations?.[0]?.address},
            {userData?.assigned_locations?.[0]?.name}
          </Text>
        </div>
      </SeparatedRowElements>

      <div className="mt-4">
        <DateFilterPaper isRange />
      </div>

      <div className="mt-4 d-flex align-items-center">
        <SeparatedRowElements containerStyle="flex-grow-1">
          {role && role !== "employee" && (
            <InfoRow
              title="Staff"
              info={statistics?.staff.toString() ?? "0"}
              isReverse
              infoFontSize={28}
              infoFontWeight="500"
            />
          )}

          <InfoRow
            title="Present"
            info={statistics?.present.toString() ?? "0"}
            isReverse
            infoFontSize={28}
            infoFontWeight="500"
          />

          <InfoRow
            title="Not In"
            info={statistics?.absent.toString() ?? "0"}
            isReverse
            infoFontSize={28}
            infoFontWeight="500"
          />

          <InfoRow
            title="Leaves and off"
            info={
              (
                (Number(statistics?.leaves) || 0) +
                (Number(statistics?.off) || 0)
              ).toString() ?? "0"
            }
            isReverse
            infoFontSize={28}
            infoFontWeight="500"
          />
          {role && role !== "employee" && (
            <InfoRow
              title="No shift"
              info={statistics?.no_shift?.toString() ?? "0"}
              isReverse
              infoFontSize={28}
              infoFontWeight="500"
            />
          )}
        </SeparatedRowElements>
        <div>
          <Button
            onClick={() => setIsDateFilterVisible(true)}
            btnClassName={styles.filterBtn}
            suffix={<Icon name="calendar" color="grey" size={24} />}
          >
            {" "}
          </Button>
          <DateFilter
            setIsVisible={() => setIsDateFilterVisible(false)}
            isVisible={isDateFilterVisible}
            isRange={false}
            isPrimary={false}
          />
        </div>
      </div>

      <div className="mt-4">
        <Table
          isCheckBoxVisible
          showSearchFilter
          headers={[
            "Members ID",
            "Department",
            "Date",
            "In-Time",
            "Out-Time",
            "Overtime",
            "Status",
          ]}
          data={
            data?.results?.map((item: AllStaffShiftsProps) =>
              changeStaffData(item)
            ) ?? []
          }
          isLoading={isEmployeeShiftsLoading || isStatisticLoading}
          count={data?.count}
          pageSize={IMPORTANT_VARS.TabelDataPageSize}
        />
      </div>
    </div>
  );
}
